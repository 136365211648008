var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Navigation',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Quick first, previous, next, last, and page buttons for pagination control of another component (such as "),_c('code',[_vm._v("<b-table>")]),_vm._v(" or lists). ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('button-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('goto')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('size')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('pill')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('alignment')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }